export const checkEmailAddress = (email) => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );
};

export const createEmailAddressForm = (onClick) => {
    const form = document.createElement("form");
    // Create label for password input
    const emailAddressLabel = document.createElement("label");
    emailAddressLabel.textContent = "Please enter email address to access the demo";
    form.appendChild(emailAddressLabel);
    // Create password input field
    const emailInput = document.createElement("input");
    emailInput.placeholder = "Email address";
    emailInput.type = "text";
    emailInput.id = "ai-solutions-email-input";
    form.appendChild(emailInput);
    const errorDiv = document.createElement("div");
    errorDiv.id = "ai-solutions-email-address-error";
    form.appendChild(errorDiv);
    //Create submit button
    const submitButton = document.createElement("button");
    submitButton.id = "ai-solution-submit-email";
    submitButton.textContent = "Submit email address";
    submitButton.onclick = onClick; // Assign onclick function
    form.addEventListener("submit", (event) => {
        event.preventDefault();
    });
    form.appendChild(submitButton);
    return { form, errorDiv };
};
